import { Avatar, Box } from '@mui/material';
import React, { FC, ReactElement } from 'react';

export const Logo: FC<{ logo: string | undefined }> = (props): ReactElement => {

  const logo = props.logo;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
    >
      {logo && <Avatar sx={{ marginTop: '-35px', width: '150px', height: `150px` }} variant="square" src={logo} />}
    </Box>
  )
}