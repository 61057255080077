import React, { ReactElement, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

interface Props {
  onSubmit: () => void;
  message: string;
  disabled?: boolean;
}

const ConfirmationButton: React.FC<Props> = ({ onSubmit, message, disabled = false }): ReactElement => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit();
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" disabled={disabled} color="primary" onClick={handleClickOpen}>
        Submit
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle><b>{message}</b></DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationButton;