import React, { FC, ReactElement, useEffect } from 'react';

import { Container } from '@mui/material';
import { Header } from '../components/header/header';
import { Footer } from '../components/footer/footer';
import { Outlet } from 'react-router-dom';
import { getProjectSites } from '../features/electrolyserData/electrolyserData.slice';
import { useAppDispatch, useAppSelector } from '../features/hook';

export const Dashboard: FC = (): ReactElement => {
  const projectSites = useAppSelector((state) => state.electrolyserDataSliceReducer.projectSites);
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log('fetching data');
    dispatch(getProjectSites());
  }, []);

  return (
    <Container sx={{ minWidth: '1400px', height: '900px', backgroundColor: '#FFF' }}>
      <Header />
      <Outlet />
      <Footer />
    </Container>
  );
};
