import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableFooter } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { useAppSelector } from "../features/hook";
import { CSVData } from "../features/interfaces/csvData";

export const EventLog: FC = (): ReactElement => {
    const data = useAppSelector((state) => state.electrolyserDataSliceReducer.csvData);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    let marginTop = 0;
    if (rowsPerPage == 5) marginTop = 353;
    if (rowsPerPage == 10) marginTop = 88;
    
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // fakeTime,h2Grams,pvSolar,concentratedSolar,battery,grid,desalinated,potable,recycled
    return (
        <>
            <TableContainer sx={{ marginBottom: `${marginTop}px`}} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="Event log">
                    <TableHead>
                        <TableRow>
                            <TableCell>Time</TableCell>
                            <TableCell>H</TableCell>
                            <TableCell>PV Solar</TableCell>
                            <TableCell>Conc. Solar</TableCell>
                            <TableCell>Battery</TableCell>
                            <TableCell>Grid</TableCell>
                            <TableCell>Desalinated</TableCell>
                            <TableCell>Potable</TableCell>
                            <TableCell>Recycled</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : data
                        ).map((row: CSVData, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{`${new Date(row.fakeTime).toISOString()}`}</TableCell>
                                <TableCell>{row.h2Grams} Grams</TableCell>
                                <TableCell>{row.pvSolar} kWh</TableCell>
                                <TableCell>{row.concentratedSolar} kWh</TableCell>
                                <TableCell>{row.battery} kWh</TableCell>
                                <TableCell>{row.grid} kWh</TableCell>
                                <TableCell>{row.desalinated} L</TableCell>
                                <TableCell>{row.potable} L</TableCell>
                                <TableCell>{row.recycled} L</TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};