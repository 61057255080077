import { Chip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, ReactElement } from 'react';
import { ILineTotalChip } from './interfaces/ILineTotalChip';

export const LineTotalChip: FC<ILineTotalChip> = (props): ReactElement => {

  const { title = '', value = '' } = props;
  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Typography fontWeight="500" fontSize="16px" sx={{ height: '1.5em' }}>{title}</Typography>
      <Chip label={value} sx={{
        fontSize: '14px',
        fontWeight: '700',
        height: '45px',
        width: '140px',
        color: '#fff',
        backgroundColor: '#000'
      }} variant="filled" />
    </Box>
  )
}
