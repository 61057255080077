import React, { ReactElement, useEffect, useState } from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { hydroverseApi } from "../lib/hydroverseApi";
import { IFormData } from "../lib/interfaces/IFormData";
import { selectAllProjectSites } from "../features/electrolyserData/electrolyserData.slice";
import { useAppSelector } from "../features/hook";
import { ProjectSite } from "../features/interfaces/projectSite";
import ConfirmationButton from "../components/shared/_confirmationButton";
import { Link, useNavigate } from "react-router-dom";

const NewSiteForm: React.FC = (): ReactElement => {
  const initialData = useAppSelector(selectAllProjectSites);
  const [navigateBack, setNavigateBack] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    label: "",
    logo: null,
    csvData: null,
  });

  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, files } = event.target;
    if (files && files.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    }
  };

  const handleFormSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setSubmitting(true);
    try {
      const alphanumericPattern = /[^a-zA-Z0-9.]+/g;
      const projectSite: ProjectSite = {
        label: formData.label,
        value: formData.label.replace(alphanumericPattern, ""),
        logo: `data/${formData.logo?.name.replace(alphanumericPattern, "")}`,
        dataFilename: `data/${formData.csvData?.name.replace(alphanumericPattern, "")}`,
      }

      const newPojectSiteData = [...initialData]
      newPojectSiteData.push(projectSite);
      console.log(newPojectSiteData)
      await hydroverseApi.postSitesJson(newPojectSiteData);
      await hydroverseApi.postNewSiteFiles(formData);
      setNavigateBack(true);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };


  const isFormValid = (): boolean => {
    return formData.label !== "" && formData.logo !== null && formData.csvData !== null;
  };

  useEffect(() => {
    if (navigateBack) {
      navigate("/editSites");
    }
  }, [navigateBack]);

  return (
    <Grid container>
      <Grid item md={2}></Grid>
      <Grid item>
        <Typography variant="h4">New Site Form</Typography>
        <form method="post" action="/">
          <Stack mt={5} sx={{ width: '400px' }} display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" mb={2}>
              <Box sx={{ width: '150px' }} mt={2}>
                <Typography fontSize="18px">Name:</Typography>
              </Box>
              <Box>
                <TextField
                  id="label"
                  name="label"
                  value={formData.label}
                  onChange={handleInputChange}
                  fullWidth

                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" mb={2}>
              <Box sx={{ width: '150px' }}>
                <Typography fontSize="18px">Logo:</Typography>
              </Box>
              <Box>
                <input
                  type="file"
                  id="logo"
                  name="logo"
                  onChange={handleFileChange}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" mb={2}>
              <Box sx={{ width: '150px' }}>
                <Typography fontSize="18px">CSV Data:</Typography>
              </Box>
              <Box>
                <input
                  type="file"
                  id="csvData"
                  name="csvData"
                  onChange={handleFileChange}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" mb={2}>
              <Box mt={3} mr={15}>
                <Link to="/editSites">Cancel</Link>
              </Box>
              <Box mt={2}>
                <ConfirmationButton disabled={!isFormValid() || submitting} onSubmit={handleFormSubmit} message="Save new site?" />
              </Box>
            </Box>
          </Stack>
        </form>
      </Grid>
      <Grid item md={2}></Grid>
    </Grid >
  );
};

export default NewSiteForm;


