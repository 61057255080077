import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { ProductionInputField } from '../shared/_productionInputField';
import iconSolar from '../../images/Icon_Solar.png'
import iconBattery from '../../images/Icon_Battery.png'
import iconGrid from '../../images/Icon_Grid.png'
import { IEnergyInput } from './interfaces/IEnergyInput';
import { Link } from 'react-router-dom';

export const EnergyInput: FC<IEnergyInput> = (props): ReactElement => {
  const {
    pvSolar = '0',
    concentratedSolar = '0',
    battery = '0',
    grid = '0' } = props;

  return (
    <Stack sx={{ width: '350px' }}>
      <Stack direction="row" spacing={2} sx={{ height: '40px', width: '100%' }}>
        <Box pl={1} pt={0.2} sx={{ width: '70%' }}>
          <Typography fontWeight="300" fontSize="18px">Energy</Typography>
        </Box>
        <Link to="eventLog" style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <Chip label="-> View Event Log" variant="outlined" onClick={(e) => console.log(e)} />
        </Link>
      </Stack>
      <ProductionInputField bgColour='#D5EDCB' icon={iconSolar} title='PV Solar' usage={`${pvSolar} kWh`} />
      <ProductionInputField bgColour='#D5EDCB' icon={iconSolar} title='Concentrated Solar' usage={`${concentratedSolar} kWh`} />
      <ProductionInputField bgColour='#D5EDCB' icon={iconBattery} title='Battery' usage={`${battery} kWh`} />
      <ProductionInputField bgColour='#D5EDCB' icon={iconGrid} title='Grid' usage={`${grid} kWh`} />
    </Stack>
  )
}