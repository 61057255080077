import { Stack } from '@mui/material';
import React, { FC, ReactElement, useEffect } from 'react';
import { csvParser } from '../../lib/csvParser';
import { CSVData } from '../../features/interfaces/csvData';
import { DataFlow } from '../dataFlow/dataFlow';
import { ProductionInputs } from '../productionInputs/productionInputs';
import { ProductionOutputs } from '../productionOutputs/productionOutputs';
import { useAppDispatch, useAppSelector } from '../../features/hook';
import { incrementRow, selectDataRow } from '../../features/electrolyserData/electrolyserData.slice';

export const ProductionAnalysis: FC = (): ReactElement => {

  const projectSite = useAppSelector((state) => state.electrolyserDataSliceReducer.selectedProjectSite);
  const dataRow = useAppSelector(selectDataRow);
  const dispatch = useAppDispatch();

  // move this code to the parser
  useEffect(() => {

    if (projectSite != null) {
      csvParser.parseFile(projectSite.dataFilename);
    }
  }, [projectSite]);

  // the core bit - this just increments the rows in the csvData and redux forces the re-render
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(incrementRow());
    }, 1000)
    return () => clearInterval(interval);
  }, [dataRow]);

  return (
    <Stack my={3} flexDirection="row">
      <ProductionInputs {...dataRow as CSVData} />
      <DataFlow {...dataRow as CSVData} />
      <ProductionOutputs {...dataRow as CSVData} />
    </Stack>
  )
}