import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { ISiteProject } from './interfaces/ISiteProject';

export const SiteProject: FC<ISiteProject> = (props): ReactElement => {

  const {
    selectedValue = '',
    items = [],
    onChange = (e) => console.log(e)
  } = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };
  return (
    <Box sx={{ marginTop: '-36px' }}>
      <Typography sx={{ color: '#7A7A7A' }} pt={2} fontWeight="400" fontSize="14px">Site Project</Typography>
      <FormControl sx={{ minWidth: 150 }}>
        <Select
          value={selectedValue}
          onChange={handleChange}
          displayEmpty
        >
          {items.map((item, index) => (
            <MenuItem key={item.value + index} selected={selectedValue == item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}