import { Box, Chip, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { ProductionInputField } from '../shared/_productionInputField';
import iconDesal from '../../images/Icon_Desal.png'
import iconPortable from '../../images/Icon_Potable.png'
import iconRecycled from '../../images/Icon_Recycled.png'
import { IWaterInput } from './interfaces/IWaterInput';
import { Link } from 'react-router-dom';

export const WaterInput: FC<IWaterInput> = (props): ReactElement => {
  const {
    desalinated = '0',
    potable = '0',
    recycled = '0'
  } = props;

  return (
    <Stack sx={{ width: '350px' }}>
      <Stack direction="row" spacing={2} sx={{ height: '40px', width: '100%' }}>
        <Box pl={1} pt={0.2} sx={{ width: '70%' }}>
          <Typography fontWeight="300" fontSize="18px">Water</Typography>
        </Box>
        <Link to="eventLog" style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <Chip label="-> View Event Log" variant="outlined" onClick={(e) => console.log(e)} />
        </Link>
      </Stack>
      <ProductionInputField bgColour='#C2EDF0' icon={iconDesal} title='Desalinated' usage={`${desalinated}L`} />
      <ProductionInputField bgColour='#C2EDF0' icon={iconPortable} title='Potable' usage={`${potable}L`} />
      <ProductionInputField bgColour='#C2EDF0' icon={iconRecycled} title='Recycled' usage={`${recycled}L`} />
    </Stack>
  )
}