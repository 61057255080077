import { Box, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { IProductionOutputs } from './interfaces/IProductionOutputs';
import { HydrogenOutput } from './_hydrogenOutput';
import { OxygenOutput } from './_oxygenOutput';

export const ProductionOutputs: FC<IProductionOutputs> = (props): ReactElement => {
  return (
    <Stack spacing={2}>
      <Box component="span">
        <Typography justifyContent="flex-end" display="flex" fontWeight="400" fontSize="26px">Outputs</Typography>
      </Box>
      <Box component="span" sx={{ p: 2, border: '2px solid rgba(0, 0, 0, 0.38);', borderRadius: '15px' }}>
        <HydrogenOutput {...props} />
      </Box>
      <Box component="span" sx={{ p: 2, border: '2px solid rgba(0, 0, 0, 0.38);', borderRadius: '15px' }}>
        <OxygenOutput />
      </Box>
    </Stack>
  )
}