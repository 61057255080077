import { Box, Chip, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';

export const OxygenOutput: FC = (): ReactElement => {
  return (
    <Stack pb={0.4} sx={{ width: '325px' }}>
      <Stack direction="row" spacing={2} sx={{ height: '40px', width: '100%' }}>
        <Box pl={0.5} pt={0.2} sx={{ width: '70%' }}>
          <Typography fontWeight="300" fontSize="18px">Oxygen/CO2/Water</Typography>
        </Box>
        {/* <Chip label="-> View Event Log" variant="outlined" onClick={(e) => console.log(e)} /> */}
      </Stack>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={0.5}
        pt={11}

        sx={{ height: '200px', border: `1px solid #C2EDF0;`, borderRadius: '7px', backgroundColor: '#C2EDF0' }}>
        <Typography fontWeight="400" fontSize="14px"><em>Currently not being tracked</em></Typography>
      </Box>
    </Stack>
  )
}