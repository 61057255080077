import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableFooter, IconButton, Grid, Avatar, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import React, { FC, ReactElement, useEffect, useState } from "react";
import { getProjectSites, reset, selectAllProjectSites } from "../features/electrolyserData/electrolyserData.slice";
import { useAppDispatch, useAppSelector } from "../features/hook";
import { ProjectSite } from "../features/interfaces/projectSite";
import { deleteElement, swapElements } from './_editUtils';
import { Box } from "@mui/system";
import { hydroverseApi } from "../lib/hydroverseApi";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationButton from "../components/shared/_confirmationButton";

export const EditSites: FC = (): ReactElement => {
    const initialData = useAppSelector(selectAllProjectSites);
    const [data, setData] = useState([] as ProjectSite[]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [navigateBack, setNavigateBack] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    let marginTop = 0;
    if (rowsPerPage == 5) marginTop = 353;
    if (rowsPerPage == 10) marginTop = 88;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const submitSiteJson = async () => {
        await hydroverseApi.postSitesJson(data);
        setNavigateBack(true);
    };

    useEffect(() => {
        dispatch(getProjectSites());
    }, [])

    useEffect(() => {
        setData(initialData);
    }, [initialData])

    useEffect(() => {
        if (navigateBack) {
            navigate("/");
        }
    }, [navigateBack]);

    return (
        <Grid container>
            <Grid item md={2}></Grid>
            <Grid item>
                <TableContainer sx={{ marginBottom: `${marginTop}px` }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="Event log">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="center"><h3>Name</h3></TableCell>
                                <TableCell align="center"><h3>Logo</h3></TableCell>
                                <TableCell align="center"><h3>CSVData</h3></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : data
                            ).map((row: ProjectSite, index: number) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        <IconButton onClick={() => setData(deleteElement(data, index))}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => setData(swapElements(data, index, index - 1))}>
                                            <VerticalAlignTopIcon color="primary" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => setData(swapElements(data, index, index + 1))}>
                                            <VerticalAlignBottomIcon color="primary" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">{row.label}</TableCell>
                                    <TableCell align="center"><Avatar sx={{ width: '150px', height: `150px` }} variant="square" src={row.logo} /></TableCell>
                                    <TableCell align="center">{row.dataFilename}</TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            <TableRow>

                            </TableRow>
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    sx={{ padding: 0, margin: 0 }}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={2}></Grid>
            <Grid item md={7}>
                <Box mt={-8} display="flex" flexDirection="row" justifyContent="flex-end">
                    <Box mt={3} mr={15}>
                        <Link to="/">Cancel</Link>
                    </Box>
                    <Box sx={{ width: '150px' }} mt={3} mr={15}>
                        <Link to="/addNewSite">Add New Site</Link>
                    </Box>
                    <Box mt={3} mr={15}>
                        <ConfirmationButton onSubmit={submitSiteJson} message="Save layout" />
                    </Box>
                </Box>
            </Grid>
        </Grid >
    );
};