import axios from "axios";
import { ProjectSite } from "../features/interfaces/projectSite";
import { IFormData } from "./interfaces/IFormData";

class HydroverseApi {
  apiUrl = 'https://p54rz7t9tb.execute-api.ap-southeast-2.amazonaws.com/dev';

  public postSitesJson = async (sites: ProjectSite[]) => {

    try {
      await axios({
        method: 'post',
        url: `${this.apiUrl}/siteJson`,
        data: sites
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  public postNewSiteFiles = async (formData: IFormData) => {

    try {

      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };

      let form = new FormData();
      if (formData.logo) {
        form.append("file", formData.logo);
      }

      let response = await axios.post(`${this.apiUrl}/upload`, form, headers);
      console.log(response.data);

      form = new FormData();
      if (formData.csvData) {
        form.append("file", formData.csvData);
      }

      response = await axios.post(`${this.apiUrl}/upload`, form, headers);
      console.log(response.data);
    }
    catch (error) {
      console.log(error);
    }
  }
}

export const hydroverseApi = new HydroverseApi();