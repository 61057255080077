import { Box, Chip, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { IHydrogenOutput } from './interfaces/IHydrogenOutput';

export const HydrogenOutput: FC<IHydrogenOutput> = (props): ReactElement => {

  const {
    h2Avg = '0',
    green = '0',
    grey = '0',
    carbon = '0',
    carbonOffset = '0'
  } = props;

  return (
    <Stack sx={{ width: '325px' }}>
      <Stack direction="row" spacing={2} sx={{ height: '40px', width: '100%' }}>
        <Box pl={0.5} pt={0.2} sx={{ width: '70%' }}>
          <Typography fontWeight="300" fontSize="18px">Hydrogen</Typography>
        </Box>
        <Link to="eventLog" style={{ color: 'inherit', textDecoration: 'inherit' }}><Chip label="-> View Event Log" variant="outlined" onClick={(e) => console.log(e)} /></Link>
      </Stack>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={0.5}
        mb={0.5}

        sx={{ height: '265px', border: `1px solid #D5EDCB;`, borderRadius: '7px', backgroundColor: '#D5EDCB' }}>
        <Typography fontWeight="700" fontSize="16px" pt={3}>In the last hour</Typography>
        <Typography fontWeight="400" fontSize="60px">{h2Avg}</Typography>
        <Typography fontWeight="400" fontSize="20px" sx={{ marginTop: '-24px' }}>grams</Typography>
        <Typography fontWeight="400" fontSize="15px" pt={2}>{carbonOffset}kgCO2-e/kgH2</Typography>
        {grey === '10' && carbonOffset === '0' && <Typography fontWeight="700" fontSize="11px" >(Green energy sourced by PPA)</Typography>}
        <Stack flexDirection="row" alignItems="center" mt={grey === '10' && carbonOffset === '0' ? 1 : 3} >
          <Box mr={1} p={0.3} sx={{ border: '1px solid #B8DDAC', borderRadius: '4px', width: '85px', height: '45px' }}>
            <Typography fontWeight="400" fontSize="10px">Green</Typography>
            <Stack flexDirection="row">
              <Chip sx={{ margin: '4px 4px 0 0', width: '15px', height: '15px', bgcolor: '#469C2E' }} />
              <Typography mt={0.2} fontWeight="700" fontSize="14px">{green}%</Typography>
            </Stack>
          </Box>
          <Box mr={1} p={0.3} sx={{ border: '1px solid #B8DDAC', borderRadius: '4px', width: '85px', height: '45px' }}>
            <Typography fontWeight="400" fontSize="10px">Grey</Typography>
            <Stack flexDirection="row">
              <Chip sx={{ margin: '4px 4px 0 0', width: '15px', height: '15px', bgcolor: '#B0B0B0' }} />
              <Typography mt={0.2} fontWeight="700" fontSize="14px">{parseInt(grey) == 0 ? '-' : `${grey}%`}</Typography>
            </Stack>
          </Box>
          <Box p={0.3} sx={{ border: '1px solid #B8DDAC', borderRadius: '4px', width: '85px', height: '45px' }}>
            <Typography fontWeight="400" fontSize="10px">Carbon</Typography>
            <Stack flexDirection="row">
              <Chip sx={{ margin: '4px 4px 0 0', width: '15px', height: '15px', bgcolor: '#168CBF' }} />
              <Typography mt={0.2} fontWeight="700" fontSize="14px">{parseInt(carbon) == 0 ? '-' : carbon}</Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Stack>
  )
}