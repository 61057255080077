import Papa, { ParseResult } from "papaparse"
import { CSVData } from "../features/interfaces/csvData";
import { store } from '../features/store';
import { addData } from "../features/electrolyserData/electrolyserData.slice";

class CSVParser {

    public parseFile(fileName: string) {
        Papa.parse(fileName, {
            header: true,
            download: true,
            skipEmptyLines: true,
            delimiter: ",",
            complete: (results: ParseResult<CSVData>) => {
                store.dispatch(addData(results.data));
            },
          })
    }

    // public fetchDataRow(): CSVData {
    //     const dataRow = this._csvData[this._rowCount++];
    //     if (this._rowCount >= this._csvData.length)
    //         this._rowCount = 0;
    //     return dataRow;
    // }

}

export const csvParser = new CSVParser();