import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { IProductionInputs } from './interfaces/IProductionInputs';
import { EnergyInput } from './_energyInput';
import { WaterInput } from './_waterInput';

export const ProductionInputs: FC<IProductionInputs> = (props): ReactElement => {

  return (
    <Stack spacing={2}>
      <Box component="span" p={0}>
        <Typography fontWeight="400" fontSize="26px">Inputs</Typography>
      </Box>
      <Box sx={{ p: 2, border: '2px solid rgba(0, 0, 0, 0.38);', borderRadius: '15px' }}>
        <EnergyInput {...props} />
      </Box>
      <Box component="span" sx={{ p: 2, border: '2px solid rgba(0, 0, 0, 0.38);', borderRadius: '15px' }}>
        <WaterInput {...props} />
      </Box>
    </Stack>
  )
}