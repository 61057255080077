import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import productionLineLeft from '../../images/productionLineLeft.png';
import productionLineRight from '../../images/productionLineRight.png';
import { DataFlowLine } from './_dataFlowLine';
import { Spinner } from './_spinner';
import { LineTotalChip } from './_lineTotalChip';
import { IDataFlow } from './interfaces/IDataFlow';
import './borderAnimation.css';

// obviously, this should break down into multiple components
export const DataFlow: FC<IDataFlow> = (props): ReactElement => {

  const { energyTotal = '0', waterTotal = '0' } = props;
  return (
    <Stack sx={{ width: '750px' }} alignItems="center">
      <Typography fontWeight="400" fontSize="26px">Production</Typography>
      <Typography sx={{ color: '#7A7A7A' }} fontWeight="400" fontSize="18px">{`< Electrolyser details >`}</Typography>
      <Stack sx={{ width: '100%' }} flexDirection="row" alignItems="center" mt={24}>
        <Box>
          <Box className="borderTopLeft"></Box>
          {/* <Avatar sx={{width: '285px', height: '120px', transform: `rotate(${rotate ? '180' : '0'}deg);`, color: `RGBA (0,0,0,0);`}} alt="hydroverse" variant="square" src={imageSrc} /> */}
        </Box>
        <Box sx={{ width: '100%' }}></Box>
        <Box>
          <Box className="borderTopRight" ></Box>
          {/* <Avatar sx={{width: '285px', height: '120px', transform: `rotate(${rotate ? '180' : '0'}deg);`, color: `RGBA (0,0,0,0);`}} alt="hydroverse" variant="square" src={imageSrc} /> */}
        </Box>
      </Stack>
      <Stack sx={{ width: '100%' }} flexDirection="row" alignItems="center" mt={10}>
        <Box>
          <Box className="borderBottomLeft" ></Box>
          {/* <Avatar sx={{width: '285px', height: '120px', transform: `rotate(${rotate ? '180' : '0'}deg);`, color: `RGBA (0,0,0,0);`}} alt="hydroverse" variant="square" src={imageSrc} /> */}
        </Box>

        <Box sx={{ width: '100%' }}></Box>
        <Box>
          <Box sx={{ width: '285px', height: '120px', border: 'solid #168CBF;', borderWidth: '0 0 5px 5px' }} ></Box>
          {/* <Avatar sx={{width: '285px', height: '120px', transform: `rotate(${rotate ? '180' : '0'}deg);`, color: `RGBA (0,0,0,0);`}} alt="hydroverse" variant="square" src={imageSrc} /> */}
        </Box>
      </Stack>
      <Spinner />
      <Stack sx={{ width: '100%' }} flexDirection="row" justifyContent="center">
        <Grid container sx={{ margin: '-363px', zIndex: '2' }}>
          <Grid item md={5}>
            <LineTotalChip title="Energy total" value={`${energyTotal} kWh`} />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={5}>
            <LineTotalChip title="Rate" value="1g / pm" />
          </Grid>
        </Grid>
      </Stack>
      <Stack sx={{ width: '100%' }} flexDirection="row" justifyContent="center">
        <Grid container sx={{ marginTop: '-50px', zIndex: '2' }}>
          <Grid item md={5}>
            <LineTotalChip title="Water total" value={`${waterTotal} mL`} />
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={5}>
            <LineTotalChip value="Other" />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}