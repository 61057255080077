import { ProjectSite } from "../features/interfaces/projectSite";

export const deleteElement = (data: ProjectSite[], index: number): ProjectSite[] => {
  const myArray: ProjectSite[] = [];
  data.forEach((obj) => {
    myArray.push(obj);
  });
  myArray.splice(index, 1);
  return myArray;
}

export const swapElements = (data: ProjectSite[], x: number, y: number): ProjectSite[] => {
  console.log(x, y);
  const myArray: ProjectSite[] = [];
  data.forEach((obj) => {
    myArray.push(obj);
  });
  const temp = myArray[x];
  console.log(temp, myArray[x], myArray[y])
  myArray[x] = myArray[y];
  myArray[y] = temp;
  return myArray;
};