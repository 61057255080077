import React, { FC, ReactElement } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { customTheme } from './theme/customTheme';
import { Dashboard } from './pages/dashboard';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from './features/store';
import { EventLog } from './pages/eventLog';
import { ProductionAnalysis } from './components/productionAnalysis/productionAnalysis';
import { EditSites } from './pages/editSites';
import NewSiteForm from './pages/newSiteForm';

// TODO MOVE initial data loading into here
const App: FC = (): ReactElement => {
  return (
    <ThemeProvider theme={customTheme}>
      <Provider store={store}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />}>
              <Route index element={<ProductionAnalysis />} />
              <Route path="eventLog" element={<EventLog />} />
            </Route>
            <Route path="editSites" element={<EditSites />} />
            <Route path="addNewSite" element={<NewSiteForm />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
