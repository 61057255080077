import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { CSVData } from '../interfaces/csvData';
import { ProjectSite } from '../interfaces/projectSite';

export const getProjectSites = createAsyncThunk<ProjectSite[], void, { rejectValue: { error: string } }>(
  "electrolyserData/getProjectSites",
  async (data, thunkApi) => {
    try {
      const response = await fetch('data/sites.json');
      return response.json();
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// Define a type for the slice state
interface AppDataState {
  csvData: CSVData[];
  rowCount: number;

  projectSites: ProjectSite[];
  selectedProjectSite: null | ProjectSite;
  loading: boolean;
  error: null | string;
}


// Define the initial state using that type
const initialState: AppDataState = {
  csvData: [],
  rowCount: 0,
  projectSites: [],
  selectedProjectSite: null,
  loading: false,
  error: null
}

export const electrolyserDataSlice = createSlice({
  name: 'electrolyserData',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {

    setProjectSite: (state, { payload }: PayloadAction<ProjectSite | undefined>) => {
      if (payload != undefined)
        state.selectedProjectSite = payload;
    },
    incrementRow: (state) => {
      if (state.rowCount + 1 < state.csvData.length)
        state.rowCount += 1
      else state.rowCount = 0;
    },
    decrementRow: (state) => {
      if (state.rowCount - 1 > 0)
        state.rowCount -= 1
      else state.rowCount = state.csvData.length - 1;
    },
    addData: (state, { payload }: PayloadAction<CSVData[]>) => {
      state.csvData = [];
      payload.forEach((row: CSVData) => {
        state.csvData.push({ ...row })
      });
      state.rowCount = state.csvData.length;
    },
    reset: (state) => {
      state.csvData = [];
      state.rowCount = 0;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectSites.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProjectSites.fulfilled, (state, { payload }: PayloadAction<ProjectSite[]>) => {
        state.loading = false;
        state.projectSites = [];
        payload.forEach((row: ProjectSite) => {
          state.projectSites.push({ ...row })
        });
        state.selectedProjectSite = state.projectSites[0];
      })
      .addCase(getProjectSites.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.projectSites = [];
      })
  }
})

// selectors
export const selectDataRow = (state: RootState): CSVData => state.electrolyserDataSliceReducer.csvData[state.electrolyserDataSliceReducer.rowCount]
export const selectProjectSite = (state: RootState): null | ProjectSite => state.electrolyserDataSliceReducer.selectedProjectSite;
export const selectAllProjectSites = (state: RootState): ProjectSite[] => state.electrolyserDataSliceReducer.projectSites;

// dispatchers
export const { setProjectSite, incrementRow, decrementRow, addData, reset } = electrolyserDataSlice.actions
