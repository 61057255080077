import { Avatar, Box, Grid, Icon, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import hyrdroverseLogo from '../../images/hydroverseLogo.png';

export const Footer: FC = (): ReactElement => {
  return (
    <Box sx={{ width: '100%', height: '70px', backgroundColor: '#000' }}
      pt={2}
      display="flex"
      flexDirection="row"
      justifyContent="flex-end">
      <Link to="editSites" style={{ color: 'inherit', textDecoration: 'inherit' }}>
        <Avatar alt="hydroverse" variant="rounded" src={hyrdroverseLogo} />
      </Link>
      <Typography sx={{ margin: '5px 50px 0 10px', color: '#fff' }} fontWeight="700" fontSize="20px">Hydroverse</Typography>
    </Box>
  )
}