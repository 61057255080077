import { Grid } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { useAppSelector, useAppDispatch } from '../../features/hook';
import { setProjectSite, selectProjectSite, selectAllProjectSites, selectDataRow } from '../../features/electrolyserData/electrolyserData.slice';
import { Logo } from './_logo';
import { ProductionDate } from './_productionDate';
import { SiteProject } from './_siteProject';
import { useNavigate } from 'react-router-dom';

export const Header: FC = (): ReactElement => {
  const navigate = useNavigate();
  const projectSite = useAppSelector(selectProjectSite);
  const allProjectSites = useAppSelector(selectAllProjectSites);
  const dataRow = useAppSelector(selectDataRow) || undefined;

  const dispatch = useAppDispatch();
  const selectNewSite = (value: string) => {
    const newSite = allProjectSites.find((site) => site.value == value);
    dispatch(setProjectSite(newSite));
    navigate('/', { replace: true });
  }

  return (
    <Grid container my={6} display="flex" sx={{ height: '75px' }} justifyContent="center">
      <Grid item md={3}>
        <SiteProject
          onChange={(site) => selectNewSite(site)} items={allProjectSites} selectedValue={projectSite?.value} />
      </Grid>
      <Grid item md={6}>
        {dataRow && <ProductionDate dateTime={dataRow.fakeTime} />}
      </Grid>
      <Grid item md={3}>
        <Logo logo={projectSite?.logo} />
      </Grid>
    </Grid>
  )
}