import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import spinnerBG from '../../images/spinnerBg.png';
import rotatingArrow from '../../images/rotatingArrow.png';

export const Spinner: FC= (): ReactElement => {
  return (    
    <Stack alignItems="center">
      <Avatar sx={{marginTop: '-300px', width: '280px', height: '280px'}} variant="rounded" src={spinnerBG} />
      <Avatar sx={{
        marginTop: '-210px', 
        width: '128px', 
        height: '135px',
        animation: "spin 3s linear infinite",
          "@keyframes spin": {
            "100%": {
              transform: "rotate(360deg)",
            },
            "0%": {
              transform: "rotate(0deg)",
            },
          }
        }} variant="rounded" src={rotatingArrow} />
      <Typography sx={{marginTop: '-100px', zIndex: '2'}} fontSize="43px" fontWeight="700">H</Typography>        
    </Stack>
  )
}