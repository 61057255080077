import { Avatar, Box, Chip, Stack } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { IProductionInputField } from './interfaces/IProductionInputField';

export const ProductionInputField: FC<IProductionInputField> = (props): ReactElement => {
  const { bgColour = '#D5EDCB', icon = 'I', title = 'Content', usage = '0.5 kWh'} = props;

  return (
    <Box sx={{padding: '4px'}}>
      <Box sx={{ p: 2, height: '60px', border: `1px solid ${bgColour};`, borderRadius: '7px', backgroundColor: `${bgColour}` }}>
        <Stack direction="row" spacing={2}>
          <Avatar sx={{width: '24px', height: '24px'}} alt={title} variant="square" src={icon} />
          <Box pt={0.5} sx={{width: '60%', fontSize: '12px'}} component="span">{title}</Box>
          <Box pt={0.5} component="span" sx={{fontSize: '12px'}}>{usage}</Box>
        </Stack>
      </Box>
    </Box>
  )
}