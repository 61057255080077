import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { FC, ReactElement } from 'react';

export const ProductionDate: FC<{dateTime: string}> = (props): ReactElement => {

  const { dateTime } = props;
  
  return (
    <Stack flexDirection="row" alignContent="center" justifyContent="center">
      <Typography sx={{ paddingRight: '8px', color: '#7A7A7A'}} pt={2} pl={10} fontWeight="400" fontSize="14px">Showing production data</Typography>        
      <Box sx={{width: '350px'}}>          
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDateTimePicker
          value={props.dateTime}
          onChange={(e) => console.log(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      </Box>
    </Stack>
  );
};
